<template>
  <router-view></router-view>
  <header class="header">
    <div class="header-left">
      <router-link to="/">
        <img class="header-title" src="https://image.ziyucc.com/website/static/logo.png"/>
        <img class="header-title" src="https://image.ziyucc.com/website/static/title.png"/>
      </router-link>
    </div>
    <div class="header-right">
      <ul class="header-links">
        <li class="links-item">
          <router-link to="/"><a>首页</a></router-link>
        </li>

        <li class="links-item">
          <router-link to="/guide"><a>购物指南</a></router-link>
        </li>

<!--        <li class="links-item">-->
<!--          <router-link to="/merchant"><a>合作商家</a></router-link>-->
<!--        </li>-->
        <li class="links-item">
          <router-link to="/settled"><a>商家入驻</a></router-link>
        </li>

        <li class="links-item">
          <router-link to="/rules"><a>平台规则</a></router-link>
        </li>
        <li class="links-item">
          <router-link to="/about_us"><a>关于我们</a></router-link>
        </li>
        <li class="links-item">
          <router-link to="/contact_us"><a>联系我们</a></router-link>
        </li>
        <li class="links-item">
          <img class="links-voice" :src="muted?voice_close:voice_open" @click="changeVoice"/>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
  data(){
    return {
      muted:true,
      voice_close:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAflBMVEVHcEwEBAQJCQkGBgYCAgIhISECAgIZGRkJCQkUFBQBAQEBAQEDAwMBAQEMDAwCAgIBAQGAgIDn5+f+/v61tbX19fX7+/v5+fmqqqrr6+u6urrz8/NUVFTV1dXDw8POzs78/PzR0dExMTFjY2OmpqagoKDj4+OEhITa2tr///8refunAAAAKXRSTlMAFA0RJwIiAQcEPy4cNQkYTAJp8Ieg9OWRxZl7YrMqb8YnVFMZbkgvlhdIE1UAAAKxSURBVEjH3VbbcuowDIzt2LEdx4EAIdwKlNI2//+DXdm5nRko6evRA3iIFq2klZwk+V8tI/uD+9KQyeVMTGb09bJqd1cjZ/r7w6qFrd70DEQm9cemjXbh5iUpafTbtvNvV8ybuXTqPX06bl64m47O9rigLyUec8qiSeM7Oqf3RQCkQmePq661h/GejoUFAHsAAA+q+mjboy3yvHgKQFmm7qBjc+VUHgGe+p1F3nIpJY6a7ybuoFPkjrEyDYCSHzaVITdk+LmpNIrg2cR/BzqpE56zAMhdhU+4SfSHjgf0njt6tIhmQafkxvgOoEKVK49fyL/dcJ0IFQBUGFvg7xkHhR6Q3kPgivPg356F7wBgnqapcqXwBi0ZAPk6IqL/WjGfxEeFKhljgiM4Zah7QFqsxwzXhRKgFB+VXMPgTjUcIiiV2wGxtimJKz5CxVG8vkt9hNSVQDTRv7E56ie7CP/2dACUgqkujUgI6mW/ARjn5bmndC7DQD2KkEwA1Zh0FQBDhOQhpejfxDzQwWxMejnuojHpyKdZLCLiE0n3/8V9KKvMJoBOGg2poBmkkdYjy8s1SnNo3HeoP81H6MeZkTSm8l4dvAE3PUrjdCdBYkDs/fRNqQp1q6cDtPlAkFEaeUHzwblwdCIteZffpjHa7Zse5e0UFClI3sIpBelrDJAqbDcO+7qjxbuJc0GRWmZYWFyEY2I49FKEcbCLY1wzm69hRL3XsXKYOe/pKA1nCAyjUryfIq2vfglI+PRLQMZGyRANcx9KYTta+6drhvYBFhltMoRKC3sclvETAGHivjSa8ulpYYcooX/f9wb5UAUirfpGE/ly3YtAa9fWu1v+at3H+y3QQmdsgb7OuLNQNOQedFDOuLIoeQoCHUBB2sy9ptGZfkfNvHmNppv9L+8OybNXhx/0DW0UydFL/QAAAABJRU5ErkJggg==',
      voice_open:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAXVBMVEVHcEwBAQECAgIjIyMTExMICAgGBgYJCQkCAgIFBQUBAQEBAQH////9/f3y8vL39/dZWVm9vb3Y2Nju7u5LS0u9vb3m5ub8/Pz19fXMzMzKysqWlpb39/eOjo7///9H0EfgAAAAHnRSTlMALiABBA0bBycUO0oB8sXnRyaldFuUteKWUG9f1jqc1BusAAACxElEQVRIx91V23ajMAzEN9km3CGkaVP+/zNXIzvg7iY9PK9OC6XWWDOSLFfV/2qGrTnv3hgLY9DJ7a0fu3brRmvO+s/txtaO3pxhb8ePLVnnUgxj3msCnVv239robA7ZzfalooPOFU9CCGP8zH/fvW3+dX/S6ZcLXjoCYB3+eXOHosY0Bj87nWm5CCBEdjI+3vHx6XIIdrRezD3pXOq6LgFhQoiYQjTMo2u3w/qlrpXaAQaAAV93SUHFPEp3plOroLVKAHf/mF3UauWvNYXwrivcQUfpGCkIgEZ+zpHUF9ZIQvhY+HdMJ5BzLgpA6U9+PmIMdSucPMt2hKVLNmzvOAlPAIkjh1glFDg5nQA1TAVif9EpgBDAdyIKKORKjgFpifMSAmuNzltTANSnOJKGiIeIyADeOkYmb9FvRQQk9KZJ491r1C4vkUPpbGqxZteQKsAAlVqFRaQlKVH1bJY9wrMeRLkuvikAxRnNgHAADq9fAUQQ2/Mb1Nq3gOqIgCx1Gfh4CzAHAAWbGJCA0VcFoNnP7SE69Pz60kRo8Ekfoo+0NiUgYOOeO0Q/cIR0KlzR3Y9RCnEAsPHKgG98Dgmgf7T3/LM1hn7rB5YAKZ2S1nB6+HGAPkbmdfTS8DVwAIKUa8qMI7WsJeI2+r29gw78S4Q509fSGUi5qvNpuKYhMB8HiNhi/MYwmcCIU2IdEGKXy9JnWpRzhwZ2EQxYSqoVz6lIOnBoHhX1MqXJ8f0cAjyBnBziq9JpamA8ugjjY8LkrsWolHJaRx0U8If9e5DxsFB1prU3DAOGaeJcFbPSpJnOoRAk00q9w4dERPJwyAFKsxZDa6c1SO8YbIPT/upCkiCBta9b2y2pskmjc68vMFnVUhgeaRFjJWu0zfsrTrRjRqUBjzvllyuV84h0aU2YUWevaY8Ce3vy3pW7y9vz7lXm/XrlD3QqWBbUqWLtAAAAAElFTkSuQmCC'
    }
  },
  mounted(){
    let ctx = this
    this.$mitt.on("closeVoice",()=>{
      ctx.muted = true
    })
    this.$mitt.on("checkVoice",()=>{
      console.log("checkVoice")
      ctx.$mitt.emit("changeVoice",{status:ctx.muted})
    })
  },
  methods:{
    changeVoice(){
      const _this = this
      this.muted = !this.muted
      this.$mitt.emit("changeVoice",{status:_this.muted})
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header{
    height: 75px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-size: 100%;
    font: inherit;
}

.header .header-title{
  height: 55px;
  margin-left: 20px;
}

.header .header-left{
  margin-left: 50px;
  min-width: 250px;
  padding-top: 15px;
}

.header .header-right{
    font-weight: 500;
    min-width: 800px;
}

.header .header-right .header-links{
   margin: 0;
   padding: 0;
   border: 0;
   vertical-align: baseline;
   display: flex;
   flex-direction: row;
   list-style: none;
   line-height: 75px;
 }

.header .header-right .header-links{
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  display: flex;
  flex-direction: row;
  list-style: none;
  line-height: 75px;

}


.header .header-right .header-links .links-item{
  margin-right: 50px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  vertical-align: middle;
  cursor: pointer;
}

.header .header-right .header-links .links-item a{
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}

.header .header-right .header-links .links-item a:hover{
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}


  .links-voice{
    height: 20px;
    margin-top: 28px;
  }
</style>
