import {createApp} from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import App from "./App";
import router from './router'
import mitt from 'mitt'
import axios from 'axios'

router.beforeEach((to)=>{
    if(to.meta.title){
        document.title = to.meta.title
    }
})

const  app = createApp(App)

app.use(router)
app.use(ElementPlus)

app.config.globalProperties.$mitt = mitt()

axios.defaults.baseURL='https://www.ziyucc.com/api';

app.config.globalProperties.$axios = axios

app.mount('#app')
