<template>
  <div class="guide">
    <div class="content">
      <div class="title">
        <span>{{title}}</span>
      </div>
      <div class="center">
        <div v-html="content" style="margin: 10px"></div>
      </div>
      <BottomOpt class="bottom"></BottomOpt>
    </div>

  </div>
</template>

<script>
import BottomOpt from "./BottomOpt";
import qs from "qs";

export default {
  components:{
    BottomOpt
  },
  name: 'Guide',
  props: {
    msg: String
  },
  data(){
    return {
      title:'',
      content:''
    }
  },
  mounted(){
    this.title = document.title
    this.getContent()
  },
  methods:{
    getContent(){
      this.$axios.post("/index/get_system_setting",qs.stringify({name:"pc_guide"}),{
      }).then(res=>{
        this.content= res.data.data.value
        console.log(res.data.data.value)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .content{
    position: absolute;
    top: 100px;
    left: 10%;
    right: 10%;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title{
    width: 100%;
    color: white;
    font-weight: 500;
    font-size: 14px;
  }
  .center{
    margin-top: 10px;
    background-color: white;
    width: 100%;
  }
  .bottom{
    width: 100%;
    height: 100px;
  }
</style>
