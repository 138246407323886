import { createRouter,createWebHistory } from 'vue-router'
import Home from "./components/Home";
import Guide from "./components/Guide";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Settled from "./components/Settled";
import License from "./components/License";
import Merchant from "./components/Merchant";
import Privacy from "./components/Privacy";
import Rules from "./components/Rules";
import Service from "./components/Service";
import Statement from "./components/Statement";

const routes =  [
    {path:'/',component:Home, meta: { title: "爱籽玉" }},
    {path:'/guide',component:Guide, meta: { title: "购物指南" }},
    {path:'/about_us',component:AboutUs, meta: { title: "关于我们" }},
    {path:'/contact_us',component:ContactUs, meta: { title: "联系我们" }},
    {path:'/settled',component:Settled, meta: { title: "商家入驻" }},
    {path:'/license',component:License, meta: { title: "营业执照" }},
    {path:'/merchant',component:Merchant, meta: { title: "合作商家" }},
    {path:'/privacy',component:Privacy, meta: { title: "隐私协议" }},
    {path:'/rules',component:Rules, meta: { title: "平台规则" }},
    {path:'/service',component:Service, meta: { title: "用户服务协议" }},
    {path:'/statement',component:Statement, meta: { title: "法律声明" }},
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router