<template>
  <div class="hello">

  </div>
</template>

<script>
export default {
  name: 'Merchant',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
